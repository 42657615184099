<template>
    <header>
        <nav class="bg-sky-700">
            <div class="mx-auto px-2 sm:px-6 lg:px-8">
                <div class="relative flex h-16 items-center justify-between">
                    <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                        <div class="flex flex-shrink-0 items-center">
                            <img class="h-8 w-auto" src="logo.png" />
                            <p class="ml-5 mr-10">L'assurance maladie
                                <br>
                                <span class="text-xs">CPAM des Alpes-Maritimes</span>
                            </p>
                        </div>
                        <div class="hidden sm:ml-6 sm:block">
                            <div class="flex space-x-4">
                                <a href="#" class="rounded-md bg-sky-900 px-3 py-2 text-sm font-medium text-white"
                                    aria-current="page">Aide au remplissage des AT/MP</a>
                            </div>
                        </div>                        
                    </div>

                    <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        <button @click="$emit('exportToPdf')" type="button" class="rounded-md bg-sky-500 px-3 py-2 text-sm font-medium text-white">Export PDF</button>
                    </div>

                    <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        <button @click="$emit('clearAll')" type="button" class="rounded-md bg-sky-500 px-3 py-2 text-sm font-medium text-white">Effacer le formulaire</button>
                    </div>

                </div>
            </div>
            
        </nav>
        
    </header>
</template>